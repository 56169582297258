import { PUB_SUB_EVENTS } from '../helper/constants';
import { fetchConfig } from '../helper/fetch-config';
import { publish } from '../helper/pubsub';

class ProductFormBundle extends HTMLElement {
  constructor() {
    super();
    this.cart = document.querySelector('cart-drawer');
  }

  connectedCallback() {
    const form = this.querySelector('form');
    const popover = this.querySelector('pop-over');
    // Add event listener to handle form submission
    form.addEventListener('submit', this.submitBundleForm.bind(this));

    //Add event listener to handle popover bundle generation
    popover.addEventListener('toggle', this.onPopover.bind(this));
  }

  onPopover(event) {
    const container = this.querySelector('.js-bundle-products-container');
    const selectedProducts = Array.from(document.querySelector('#product-select').children);
    const selectedProductContainer = document.createElement('div');

    selectedProducts.forEach((product) => {
      const productCard = document.createElement('div');
      productCard.classList.add('relative', 'text-left');
      const media = product.querySelector('.js-bundle-media').cloneNode(true);
      const title = product.querySelector('.js-product-title').cloneNode(true);
      title.className = 'block font-bold w-28';
      const price = product.querySelector('.js-main-price').cloneNode(true);
      const checkedInputs = product.querySelectorAll('input[checked]');

      productCard.prepend(media);
      productCard.append(title);
      productCard.append(price);

      selectedProductContainer.append(productCard);
    });
    container.innerHTML = selectedProductContainer.innerHTML;
  }

  // Function to handle the form submission
  submitBundleForm(event) {
    event.preventDefault();
    // Get the product input groups
    const productInputGroups = this.querySelectorAll('product-input-group');

    // Generate a random _bundle number (you could also use UUID if preferred)
    const bundleId = Math.floor(Math.random() * 1000000);

    // Create empty form data
    const formData = new FormData();

    // Loop through the product IDs and create an input for each
    productInputGroups.forEach((inputGroup, index) => {
      const id = inputGroup.querySelector('input[name="id"]').value;
      const quantity = 1;
      const property = bundleId;
      const source = 'pdp';
      const parent = inputGroup.querySelector('input[name="parent"]').value;

      console.log(parent);

      formData.append(`items[${index}][id]`, id);
      formData.append(`items[${index}][quantity]`, quantity);
      formData.append(`items[${index}][properties][_bundle]`, property);
      formData.append(`items[${index}][properties][_source]`, source);
      formData.append(`items[${index}][properties][_parent]`, parent);
    });

    formData.append(
      'sections',
      this.cart.getSectionsToRender().map((section) => section.id)
    );
    formData.append('sections_url', window.location.pathname);

    const config = fetchConfig('javascript');
    config.headers['X-Requested-With'] = 'XMLHttpRequest';
    delete config.headers['Content-Type'];
    config.body = formData;

    fetch(`${routes.cart_add_url}`, config)
      .then((response) => response.json())
      .then((response) => {
        if (response.status) {
          publish(PUB_SUB_EVENTS.cartError, {
            source: 'product-form',
            productVariantId: formData.get('id'),
            errors: response.description,
            message: response.message
          });
          this.handleErrorMessage(response.description);

          const soldOutMessage = this.submitButton.querySelector('.sold-out-message');
          if (!soldOutMessage) return;
          this.submitButton.setAttribute('aria-disabled', true);
          this.submitButton.querySelector('span').classList.add('hidden');
          soldOutMessage.classList.remove('hidden');
          this.error = true;
          return;
        } else if (!this.cart) {
          window.location = window.routes.cart_url;
          return;
        }

        if (!this.error)
          publish(PUB_SUB_EVENTS.cartUpdate, { source: 'product-form', productVariantId: formData.get('id') });
        this.error = false;
        console.log(response);
        this.cart.trigger(this.cart);
        this.cart.renderContents(response);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        if (this.cart && this.cart.classList.contains('is-empty')) this.cart.classList.remove('is-empty');
        this.querySelector('pop-over').closePopover();
      });
  }
}

// Define the custom element
customElements.define('product-form-bundle', ProductFormBundle);

class ColorSwatch extends HTMLElement {
  constructor() {
    super();
    this.handle = this.getAttribute('handle');
    this.sectionId = this.getAttribute('section-id');
    this.variantRadios = document.querySelector('variant-radios');
    this.input = this.querySelector('input');
    this.template = this.getAttribute('template');
    this.addEventListener('change', this.onColorUpdate.bind(this));
  }

  onColorUpdate(event) {
    console.log('update');
    event.preventDefault();
    fetch(`/products/${this.handle}?section_id=${this.sectionId}&view=${this.template}`)
      .then((response) => response.text())
      .then((responseText) => {
        const html = new DOMParser().parseFromString(responseText, 'text/html').querySelector('.js-product-main');
        const currentInputGroup = this.closest('product-input-group');
        const updatedInputGroup = html.querySelector('product-input-group');

        const currentProductTitle = currentInputGroup.querySelector('.js-product-title');
        const updatedProductTitle = html.querySelector('.js-product-title');

        const bundleVariantSelect = html.querySelector('.js-swatch-fetch').querySelector('bundle-variant-radios');
        const currentVariantSelect = this.closest('bundle-variant-radios');

        const bundleMedia = html.querySelector('.js-bundle-media');
        const currentMedia = currentInputGroup.querySelector('.js-bundle-media');

        const bundleVariantId = currentInputGroup.querySelector('input[name="id"]');
        const updatedVariantId = updatedInputGroup.querySelector('input[name="id"]');

        bundleVariantId.value = updatedVariantId.value;

        if (currentProductTitle) {
          currentProductTitle.innerHTML = updatedProductTitle.innerHTML;
        }

        if (currentVariantSelect) {
          currentVariantSelect.querySelector('.js-swatch-info').innerHTML =
            bundleVariantSelect.querySelector('.js-swatch-info').innerHTML;
        }

        currentMedia.innerHTML = bundleMedia.innerHTML;
      })
      .catch((e) => {
        console.error(e);
      });
  }
}

customElements.define('swatch-selector-bundle', ColorSwatch);

class RiseSelect extends HTMLElement {
  constructor() {
    super();
    this.riseGroups = Array.from(this.parentElement.querySelectorAll('.js-swatch-rise-wrapper'));
    this.addEventListener('change', this.onRiseUpdate.bind(this));
  }

  onRiseUpdate(event) {
    event.preventDefault();
    const updatedRiseGroup = Array.from(this.parentElement.querySelectorAll('.js-swatch-rise-wrapper'));
    event.target.setAttribute('checked', true);

    const selectedRiseSwatchGroup = updatedRiseGroup.filter((rise) => {
      return rise.getAttribute('rise') === event.target.value;
    });

    updatedRiseGroup.forEach((rise) => {
      rise.classList.remove('hidden');
    });

    updatedRiseGroup.forEach((rise) => {
      if (rise != selectedRiseSwatchGroup[0]) {
        rise.classList.add('hidden');
      }
    });

    const currentSwatch = this.parentElement.querySelector('.js-selected').textContent.trim();

    console.log(currentSwatch);
    if (selectedRiseSwatchGroup[0].querySelector(`input`) && currentSwatch) {
      //click the other swatch
      selectedRiseSwatchGroup[0].querySelector(`input[label="${currentSwatch}"]`).click();
      //for redundancy also trigger the event response for click to make sure the ID changes.
      selectedRiseSwatchGroup[0]
        .querySelector(`input[label="${currentSwatch}"]`)
        .closest('swatch-selector-bundle')
        .onColorUpdate(event);
    }
  }
}

customElements.define('rise-selector-bundle', RiseSelect);

class SizeSelect extends HTMLElement {
  constructor() {
    super();
    this.buttons = this.querySelectorAll('button');
  }

  connectedCallback() {
    this.buttons.forEach((button) => {
      button.addEventListener('click', this.onSizeSelect.bind(this));
    });
  }

  onSizeSelect(event) {
    this.handle = event.target.getAttribute('handle');
    this.sectionId = event.target.getAttribute('section-id');
    this.template = event.target.getAttribute('template');
    event.preventDefault();
    fetch(`/products/${this.handle}?section_id=${this.sectionId}&view=${this.template}`)
      .then((response) => response.text())
      .then((responseText) => {
        const html = new DOMParser().parseFromString(responseText, 'text/html').querySelector('.js-product-main');
        const currentProduct = document.querySelector('.js-product-main');

        const updatedBundleSizeSelects = html.querySelectorAll('bundle-size-select');
        const bundleSizeSelects = document.querySelectorAll('bundle-size-select');

        [...updatedBundleSizeSelects].forEach((updatedElement, index) => {
          const bundles = [...bundleSizeSelects];
          updatedElement.innerHTML = bundles[index].innerHTML;
        });

        // html.querySelector('bundle-size-select').innerHTML =
        //   currentProduct.querySelector('bundle-size-select').innerHTML;
        [...html.querySelectorAll(`[handle="${event.target.getAttribute('handle')}"]`)].forEach((child) => {
          [...child.parentElement.children].forEach((nestedChild) => {
            nestedChild.classList.remove('bg-[#F3C6E1]');
          });
        });

        [...html.querySelectorAll(`[handle="${event.target.getAttribute('handle')}"]`)].forEach((child) => {
          child.classList.add('bg-[#F3C6E1]');
        });
        currentProduct.innerHTML = html.innerHTML;
      })
      .catch((e) => {
        console.error(e);
      });
  }
}

customElements.define('bundle-size-select', SizeSelect);

class Popover extends HTMLElement {
  constructor() {
    super();
    this.target = this.querySelector('[custompopover]');
    this.toggleButtons = this.querySelectorAll('[popovertarget]');
  }

  connectedCallback() {
    [...this.toggleButtons].forEach((button) => {
      button.addEventListener('click', this.onClickEvent.bind(this));
    });

    this.target.addEventListener('click', this.onClickEvent.bind(this));
  }

  onClickEvent(event) {
    const isActionHide =
      event.target.getAttribute('targetaction') == 'hide' || event.target == this.target ? true : false;

    if (isActionHide) {
      this.target.classList.add('hidden');
    } else {
      this.target.classList.remove('hidden');
    }

    const toggleEvent = new Event('toggle');

    this.dispatchEvent(toggleEvent);
  }

  closePopover() {
    this.target.classList.add('hidden');
  }
}

customElements.define('pop-over', Popover);

